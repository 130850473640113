
import {
    IonPage,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonSearchbar,
    IonSelect,
    IonSelectOption,
    IonItem,
    IonContent,
    IonCard,
    IonCardHeader,
    IonCardTitle,
    IonCardSubtitle,
    IonCardContent,
    IonBadge,
    IonSpinner,
} from '@ionic/vue';
import HeaderOne from '@/components/HeaderOne.vue';
import { directus } from '../main'

export default {
  name: 'Ansprechpartner',
  components: {
    IonHeader,
    IonToolbar,
    IonTitle,
    IonPage,
    HeaderOne,
    IonSearchbar,
    IonSelect,
    IonSelectOption,
    IonItem,
    IonContent,
    IonCard,
    IonCardHeader,
    IonCardTitle,
    IonCardSubtitle,
    IonCardContent,
    IonBadge,
    IonSpinner,
  },
  data: function() {
    return {
      search: '',
      loading: false,
      error: null,
      persons: null,
    }
  },
  mounted() {
    this.error = this.persons = null;
    this.loading = true;
    async function getPersons() {
      // ... before fetching items
      return await directus.items('ansprechpartner').read({
        fields: ['id', 'bild', 'name', 'titel', 'email', 'telefon', 'organisationsbereich.*'],
        filter: {
          status: {
            "_eq": "published"
          }
        }
      });
    }
    getPersons().then(persons => {
      this.persons = persons.data;
      this.loading = false;
    }).catch(err => {
        this.error = err.toString();
      })
  }
}
