<template>
  <ion-page>
    <HeaderOne v-bind:name="$t('contacts')" />
    <ion-content :fullscreen="true">
      <ion-header collapse="condense">
        <ion-toolbar>
          <ion-title size="large">
            {{$t('contacts')}}
          </ion-title>
        </ion-toolbar>
      </ion-header>

      <ion-searchbar v-model="search" v-bind:placeholder="$t('search')" />

      <div v-if="loading" class="loading">
        <ion-spinner name="crescent" color="primary"></ion-spinner>
        <h4>Lädt</h4>
      </div>

      <div v-if="error" class="loading">
        {{ error }}
      </div>

      <template v-for="person in persons" :key="person.id">
        <ion-card>
          <img :src="'https://cms.app.thb.torben.wtf/assets/' + person.bild" :alt="person.name"/>
          <ion-card-header>
            <ion-card-title>{{ person.name }}</ion-card-title>
            <ion-card-subtitle>
              <ion-badge>{{ person.titel }}</ion-badge><br/>
              {{ person.organisationsbereich.name }}
            </ion-card-subtitle>
          </ion-card-header>
          <ion-card-content>
            <strong>Email:</strong> <a v-bind:href="'mailto:' + person.email">{{person.email}}</a><br/>
            <strong>{{$t('phone')}}:</strong> <a v-bind:href="'tel:' + person.telefon">{{person.telefon}}</a>
          </ion-card-content>
        </ion-card>
      </template>

    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import {
    IonPage,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonSearchbar,
    IonSelect,
    IonSelectOption,
    IonItem,
    IonContent,
    IonCard,
    IonCardHeader,
    IonCardTitle,
    IonCardSubtitle,
    IonCardContent,
    IonBadge,
    IonSpinner,
} from '@ionic/vue';
import HeaderOne from '@/components/HeaderOne.vue';
import { directus } from '../main'

export default {
  name: 'Ansprechpartner',
  components: {
    IonHeader,
    IonToolbar,
    IonTitle,
    IonPage,
    HeaderOne,
    IonSearchbar,
    IonSelect,
    IonSelectOption,
    IonItem,
    IonContent,
    IonCard,
    IonCardHeader,
    IonCardTitle,
    IonCardSubtitle,
    IonCardContent,
    IonBadge,
    IonSpinner,
  },
  data: function() {
    return {
      search: '',
      loading: false,
      error: null,
      persons: null,
    }
  },
  mounted() {
    this.error = this.persons = null;
    this.loading = true;
    async function getPersons() {
      // ... before fetching items
      return await directus.items('ansprechpartner').read({
        fields: ['id', 'bild', 'name', 'titel', 'email', 'telefon', 'organisationsbereich.*'],
        filter: {
          status: {
            "_eq": "published"
          }
        }
      });
    }
    getPersons().then(persons => {
      this.persons = persons.data;
      this.loading = false;
    }).catch(err => {
        this.error = err.toString();
      })
  }
}
</script>
